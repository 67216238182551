<template>
    <div class="p-4 flex">
        <div class="bg-gradient-to-br from-calypso-700 to-emerald-700 p-8 rounded-lg shadow-xl flex flex-col gap-8">
            <div class="flex flex-col gap-8 max-w-xs">
                <div class="text-2xl font-bold underline text-center">
                    API Access Coming Soon
                </div>
                <div>
                    For beta access or more information, please contact <a href="https://discord.gg/pfcR8EQZjb"
                        class="italic underline">0xreed</a> on Discord, or reach out to
                    us on Twitter <a href="https://twitter.com/AlphDotPro" target="_blank"
                        class="italic">@AlphDotPro</a>
                </div>
            </div>
        </div>
    </div>

</template>
